<template>
<b-card>
        <div>
        <span><h4>Add Asset Categories</h4></span>
        <div>
            <label>Name</label>
            <input v-model="assetName" placeholder="name" class=" mr-bottom form-control input-sm flex" />
            <span class="validation" v-if="duplicateName"> Please enter valid name</span>
        </div>
        <label>Description</label>
        <b-form-textarea rows="1" v-model="assetsDescription" placeholder="description" class="mr-bottom" />
        <div class="align-right">
            <button @click="addAssets()" class="btn pointer btn-primary btn-sm margin-right">Add</button>
            <button @click="cancel()" class="btn pointer btn-sm ">Cancel</button>
        </div>
    </div>
</b-card>
</template>

<script>
import Vue from 'vue';
import { BFormTextarea, BSidebar, VBToggle, BPagination , BCard} from 'bootstrap-vue'
export default {
  data: () => ({
    allAssets: [],
    assetsDescription: '',
    assetName:'',
    addAssetCategories: false,
    editAssetCategories: false,
    editAssetID: '',
    hideSideBar: false,
    duplicateName: false,
    currentPage: 1,
    lastPage: null,
    perPage: 10,
    rows: 1,
    searchResult: ''
  }),
components: {
    BFormTextarea,
    BCard
    // BSidebar,
    // BPagination
},
props: {
   
},

mounted(){
    
},
computed: {

},
methods: {

    addAssets () {
         const body = {
            name: this.assetName,
            description: this.assetsDescription,
        }
       const duplicateData =  this.allAssets.filter(ele => ele.name.toLowerCase()  == this.assetName.toLowerCase())
       if(!duplicateData.length){
        const options = {
            method: "POST",
            data: body,
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/ast_categories`,
        };
        this.$http(options)
            .then((res) => {
            //  this.getAssets()
             this.hideSideBar = !this.hideSideBar
             this.duplicateName = false
             this.assetName = ''
             this.assetsDescription =''
             this.$router.push({name: 'assets'})
            })
            .catch((err) => {
                console.log(err);
            });
       } else {
        this.duplicateName = true
       }
        
    },
    cancel(){
        this.$router.go(-1)
    },
},

}
</script>

<style>
.header{
    font-size: 20px;
    font-weight: 600;
}
.align-center-flex {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.align-center-div {
    display: flex;
    align-items: center;
    justify-content: center;
}
.padding-20 {
    padding: 20px;
}
.width-70 {
    width: 70%;
}
/* .table:not(.table-dark):not(.table-light) 
thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) 
tfoot:not(.thead-dark) th{
    background-color: #f3f2f7 !important;
    color:#6e6b7b
} */
.align-right {
    display: flex;
    justify-content: flex-end;
}
.icon-box-new {
  width: 25px;
  height: 25px;
  background-color: #7367f0;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  display: inline-flex;
} 
.mr-bottom {
    margin-bottom: 20px;
}
.pointer {
    cursor: pointer;
}
.validation {
    color: red;
    font-size: 12px;
    position: relative;
    top: -10px;
}
.table th, .table td {
    border-top: 1px solid #e3e0e9;
}
.rowgroup tr:hover{
    background-color: #BFCAD0;
    cursor: pointer;
}
.text-align-right {
    text-align: right;
}

</style>
